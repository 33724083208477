import React, { Component } from "react"
import { graphql, navigate } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse, { domToReact } from 'html-react-parser'
import { globalHistory } from '@reach/router'
import Layout from "../components/layout"
import SEO from "../components/seo"
import SideNav from '../components/sidenav'
import { Carousel } from 'react-responsive-carousel'
import ContentFormatter from '../components/project-content-formatter'

class ProjectsAZ extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: this.props.data.allWpProject.edges,
      selectedTextures: [],
      selectedColors: [],
      selectedFinishes: [],
      selectedMarkets: [],
      selectedLocations: [],
      hideFiltered: false,
      hideFilters: false
    }
  }
  componentDidMount() {
    var pageQuery = this.state.query;
    if(globalHistory.location.search){
      pageQuery = this.parseQuery(globalHistory.location.search)
      if(pageQuery.url){
        var url = pageQuery.url
      }
    }
  }

  parseQuery(queryString){
    var query = {}
    var pairs = (queryString[0] === '?'
      ? queryString.substr(1)
      : queryString
    ).split('&')
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=')
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }
    return query
  }
  filterByTexture(texture){
    let searchTerm = texture
    let selectedTerms = this.state.selectedTextures
    if(selectedTerms.includes(searchTerm)){
      selectedTerms.splice(selectedTerms.indexOf(searchTerm),1)
    }else{
      selectedTerms.push(searchTerm)
    }
    this.setState({
      selectedTextures: selectedTerms
    }, function(){
      this.filterProjects()
    })
  }
  filterByColor(color){
    let searchTerm = color
    let selectedTerms = this.state.selectedColors
    if(selectedTerms.includes(searchTerm)){
      selectedTerms.splice(selectedTerms.indexOf(searchTerm),1)
    }else{
      selectedTerms.push(searchTerm)
    }
    this.setState({
      selectedColors: selectedTerms
    }, function(){
      this.filterProjects()
    })
  }
  filterByFinish(finish){
    let searchTerm = finish
    let selectedTerms = this.state.selectedFinishes
    if(selectedTerms.includes(searchTerm)){
      selectedTerms.splice(selectedTerms.indexOf(searchTerm),1)
    }else{
      selectedTerms.push(searchTerm)
    }
    this.setState({
      selectedFinishes: selectedTerms
    }, function(){
      this.filterProjects()
    })
  }
  filterByMarket(market){
    let searchTerm = market
    let selectedTerms = this.state.selectedMarkets
    if(selectedTerms.includes(searchTerm)){
      selectedTerms.splice(selectedTerms.indexOf(searchTerm),1)
    }else{
      selectedTerms.push(searchTerm)
    }
    this.setState({
      selectedMarkets: selectedTerms
    }, function(){
      this.filterProjects()
    })
  }
  filterByLocation(location){
    let searchTerm = location
    let selectedTerms = this.state.selectedLocations
    if(selectedTerms.includes(searchTerm)){
      selectedTerms.splice(selectedTerms.indexOf(searchTerm),1)
    }else{
      selectedTerms.push(searchTerm)
    }
    this.setState({
      selectedLocations: selectedTerms
    }, function(){
      this.filterProjects()
    })
  }
  hasTexture(project, selectedTextures){
    let selectedTerms = selectedTextures
    let projectTerms = project.node.textures.nodes.map(item => {
      return item.slug
    })
    if(selectedTerms.length > 0){
      let hasSelected = false
      selectedTerms.forEach((term) => {
        if(projectTerms.includes(term)){
          hasSelected = true
        }
      })
      return hasSelected
    }else{
      return true
    }
  }
  hasColor(project, selectedColors){
    let selectedTerms = selectedColors
    let projectTerms = project.node.colors.nodes.map(item => {
      return item.slug
    })
    if(selectedTerms.length > 0){
      let hasSelected = false
      selectedTerms.forEach((term) => {
        if(projectTerms.includes(term)){
          hasSelected = true
        }
      })
      return hasSelected
    }else{
      return true
    }
  }
  hasFinish(project, selectedFinishes){
    let selectedTerms = selectedFinishes
    let projectTerms = project.node.finishes.nodes.map(item => {
      return item.slug
    })
    if(selectedTerms.length > 0){
      let hasSelected = false
      selectedTerms.forEach((term) => {
        if(projectTerms.includes(term)){
          hasSelected = true
        }
      })
      return hasSelected
    }else{
      return true
    }
  }
  hasMarket(project, selectedMarkets){
    let selectedTerms = selectedMarkets
    let projectTerms = project.node.markets.nodes.map(item => {
      return item.slug
    })
    if(selectedTerms.length > 0){
      let hasSelected = false
      selectedTerms.forEach((term) => {
        if(projectTerms.includes(term)){
          hasSelected = true
        }
      })
      return hasSelected
    }else{
      return true
    }
  }
  hasLocation(project, selectedLocations){
    let selectedTerms = selectedLocations
    let projectTerms = project.node.locations.nodes.map(item => {
      return item.slug
    })
    if(selectedTerms.length > 0){
      let hasSelected = false
      selectedTerms.forEach((term) => {
        if(projectTerms.includes(term)){
          hasSelected = true
        }
      })
      return hasSelected
    }else{
      return true
    }
  }
  filterProjects(){
    let projects = []
    this.props.data.allWpProject.edges.forEach((project, i) => {
      if(!(this.hasTexture(project, this.state.selectedTextures) &&
        this.hasColor(project, this.state.selectedColors) &&
        this.hasFinish(project, this.state.selectedFinishes) &&
        this.hasMarket(project, this.state.selectedMarkets) &&
        this.hasLocation(project, this.state.selectedLocations)
      )){
        let projectTemp = {...project}
        projectTemp.filtered = true
        projects.push(projectTemp)
      }else{
        projects.push(project)
      }
    })
    this.setState({
      projects: projects,
      hideFiltered: false
    }, function(){
      setTimeout(function(){
        this.setState({
          hideFiltered: true
        })
      }.bind(this),500)
    }.bind(this))
  }
  render() {

    let projects = this.props.data.allWpProject.edges
    // Create filter variables here
    let textures = this.props.data.allWpTexture.edges
    let colors = this.props.data.allWpColor.edges
    let finishes = this.props.data.allWpFinish.edges
    let markets = this.props.data.allWpMarket.edges
    let locations = this.props.data.allWpLocation.edges
    let availableTextures = []
    let availableColors = []
    let availableFinishes = []
    let availableMarkets = []
    let availableLocations = []

    let projectsList = this.state.projects.map(function(project, i){
      let source = null
      let mediaItemUrl = null
      if(project.node){
        if(project.node.featuredImage){
          if(project.node.featuredImage.node){
            if(project.node.featuredImage.node.localFile){
              source = getImage(project.node.featuredImage.node.localFile)
            }
            if(project.node?.featuredImage?.node.mediaItemUrl){
              mediaItemUrl = project.node.featuredImage.node.mediaItemUrl
            }
          }
        }
      }
      if(!project.filtered){
        project.node.textures.nodes.forEach((item, i) => {
          availableTextures.push(item.slug)
        })
        project.node.colors.nodes.forEach((item, i) => {
          availableColors.push(item.slug)
        })
        project.node.finishes.nodes.forEach((item, i) => {
          availableFinishes.push(item.slug)
        })
        project.node.markets.nodes.forEach((item, i) => {
          availableMarkets.push(item.slug)
        })
        project.node.locations.nodes.forEach((item, i) => {
          availableLocations.push(item.slug)
        })
      }
      return(
        <div className={'project' + (project.filtered ? ' filtered' : '')
          + (this.state.hideFiltered ? ' hidden' : '')} key={i}>
          <Link to={project.node.uri}  title={project.node.title}>
            {source ?
              <GatsbyImage image={source} alt={project.node.title} />
              :
              <img src={mediaItemUrl} />
            }

            <div className="info">
              <div className="project-title" dangerouslySetInnerHTML={{__html: project.node.title}} />
              <div className="project-location">{project.node.location}</div>
            </div>
          </Link>
        </div>
      )
    }.bind(this))
    if(!projectsList.length){
      projectsList = <p style={{textAlign: 'center', width: '100%'}}>Sorry, no results were found. Please reset the search and try a different combination.</p>
    }
    let textureSelectors = textures.map((item)=>{
      return <button onClick={(e)=>{this.filterByTexture(item.node.slug)}}
      disabled={!availableTextures.includes(item.node.slug)}
      className={'project-filter-button'+(this.state.selectedTextures.includes(item.node.slug) ? ' active' : '')}>
      {item.node.name}
      </button>
    })
    let colorSelectors = colors.map((item)=>{
      return <button onClick={(e)=>{this.filterByColor(item.node.slug)}}
      disabled={!availableColors.includes(item.node.slug)}
      className={'project-filter-button'+(this.state.selectedColors.includes(item.node.slug) ? ' active' : '')}>
      {item.node.name}
      </button>
    })
    let finishSelectors = finishes.map((item)=>{
      return <button onClick={(e)=>{this.filterByFinish(item.node.slug)}}
      disabled={!availableFinishes.includes(item.node.slug)}
      className={'project-filter-button'+(this.state.selectedFinishes.includes(item.node.slug) ? ' active' : '')}>
      {item.node.name}
      </button>
    })
    let marketSelectors = markets.map((item)=>{
      return <button onClick={(e)=>{this.filterByMarket(item.node.slug)}}
      disabled={!availableMarkets.includes(item.node.slug)}
      className={'project-filter-button'+(this.state.selectedMarkets.includes(item.node.slug) ? ' active' : '')}>
      {item.node.name}
      </button>
    })
    let locationSelectors = locations.map((item)=>{
      return <button onClick={(e)=>{this.filterByLocation(item.node.slug)}}
      disabled={!availableLocations.includes(item.node.slug)}
      className={'project-filter-button'+(this.state.selectedLocations.includes(item.node.slug) ? ' active' : '')}>
      {item.node.name}
      </button>
    })

    const StaticPage = this.props.data.wpPage
    //Fix self-closing spacers, ampersand tags and returns
    StaticPage.content = StaticPage.content
      .replace(/<div (class="kt-block-spacer.*)\/>/gi,'<div $1></div>')
      .replace(/&amp;nbsp;/gi, '&nbsp;')
      .replace(/\t/gi, '')
      .replace(/\n/gi, '')
    let contentAdditional = []
    let content = []
    let tempContent = StaticPage.content

    let components = []
    if(!parse(tempContent).length) {
      components.push(parse(tempContent))
    }else{
      components = parse(tempContent)
    }
    content = components.map(function(element, i){
      let className = ''
      if(element.props){
        if(element.props.className){
          className = element.props.className
        }
      }
      if(element.type === 'div' && className.includes('gallery')){
        return (null)
      }else if(element.type === 'figure' && className.includes('gallery')){
        return (null)
      }else if(element.type === 'style'){
        return(null)
      }else{
        if(element){
          if(element.type){
            if(!(element.type === 'p' && !element.props.children)){
              contentAdditional.push(element)
            }
          }
        }
        return(
          null
        )
      }
    })
    if(contentAdditional.length > 0){
      contentAdditional = <div className="project-moreinfo content">{contentAdditional}</div>
    }
    setTimeout(function(){
      projectsList = projectsList.filter((project) => {
        return project.props.className.includes('filtered')
      })
    },500)
    return (
      <Layout location={this.props.location} className='projects'>
        <SEO title={'Projects A-Z'} />
        <div className="columns">
          <ContentFormatter content={StaticPage.content} />
        </div>
        <div className="section" style={{display: 'none'}}>
          <div className="columns">
            <div className="column">
              <button className={'filters-hide-button '+ (this.state.hideFilters ? 'hide-filters' : '')}
              onClick={(e)=>{
                this.setState({
                  hideFilters: !this.state.hideFilters
                })
              }}>
                Filter
              </button>
            </div>
          </div>
        </div>
        <div className={"section filters-section " + (this.state.hideFilters ? 'hide-filters' : '')} style={{display: 'none'}}>
          <div className="columns is-mobile" style={{flexWrap: 'wrap'}}>
            <div className="column is-half-mobile">
              <h3 className="project-filter-title">Surface</h3>
              <div className="project-filters">
                {textureSelectors}
              </div>
            </div>
            <div className="column is-half-mobile">
              <h3 className="project-filter-title">Color Family</h3>
              <div className="project-filters">
                {colorSelectors}
              </div>
            </div>
            <div className="column is-half-mobile">
              <h3 className="project-filter-title">Finish</h3>
              <div className="project-filters">
                {finishSelectors}
              </div>
            </div>
            <div className="column is-half-mobile">
              <h3 className="project-filter-title">Market</h3>
              <div className="project-filters">
                {marketSelectors}
              </div>
            </div>
            <div className="column is-half-mobile">
              <h3 className="project-filter-title">Region</h3>
              <div className="project-filters">
              {locationSelectors}
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="projects-az-full section">
            {projectsList}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProjectsAZ

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(id: { eq: $id }) {
      title
      content
      template {
        templateName
      }
      seo {
        metaDesc
        opengraphImage {
          sourceUrl
          mediaItemUrl
        }
        metaKeywords
        opengraphDescription
      }
    }
    allWpProject(sort: {fields: title, order: ASC}) {
      edges {
        node {
          id
          title
          uri
          architect
          color
          texture
          location
          latitude
          longitude
          textures {
            nodes {
              name
              slug
              id
            }
          }
          colors {
            nodes {
              name
              slug
              id
            }
          }
          finishes {
            nodes {
              name
              slug
              id
            }
          }
          markets {
            nodes {
              name
              slug
              id
            }
          }
          locations {
            nodes {
              name
              slug
              id
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              mediaItemUrl
            }
          }
        }
      }
    }
    allWpTexture(sort: {order: ASC, fields: name}) {
      edges {
        node {
          name
          slug
          description
        }
      }
    }
    allWpColor(sort: {order: ASC, fields: name}) {
      edges {
        node {
          name
          slug
          description
        }
      }
    }
    allWpFinish(sort: {order: ASC, fields: name}) {
      edges {
        node {
          name
          slug
          description
        }
      }
    }
    allWpMarket(sort: {order: ASC, fields: name}) {
      edges {
        node {
          name
          slug
          description
        }
      }
    }
    allWpLocation(sort: {order: ASC, fields: name}) {
      edges {
        node {
          name
          slug
          description
        }
      }
    }
    site {
      id
      siteMetadata {
        title
        description
      }
    }
  }
`
